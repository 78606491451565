<template>
  <v-row class="app-preview">
    <v-col
      cols="12"
      md="9"
    >
      <template>
        <v-card
          v-if="invoice"
          class="printable--theme mb-3"
          :class="{ 'mobile-text' : $vuetify.breakpoint.xs }"
        >
          <div class="pb-2 px-5">
            <!-- Letterhead -->
            <Letterhead />
          </div>

          <v-divider class="printable-divider"></v-divider>

          <div class="px-5 px-sm-12 pt-2 pb-0">
            <v-row>
              <!-- Customer Details -->
              <v-col
                cols="7"
                sm="8"
              >
                <p class="me-2 mb-0 printable-text font-weight-medium">
                  Date Issued: {{ invoice.created_at }}
                </p>
                <p class="me-2 printable-text font-weight-medium">
                  Due Date: {{ invoice.due_date }}
                </p>
                <p class="text-decoration-underline font-weight-semibold payment-details-header mb-1">
                  Invoice To:
                </p>
                <p class="mb-0">
                  {{ invoice.customer_name }} ({{ invoice.customer_contact_number }})
                </p>
                <p class="mb-0">
                  {{ invoice.insurance.name }}
                </p>
                <template v-if="invoice.insurance_product.name === 'Motor insurance'">
                  <p class="mb-0">
                    Car registration: {{ invoice.car_registration_number }}
                  </p>
                </template>
                <p class="mb-0">
                  Sum insured: {{ invoice.sum_insured }}
                </p>
                <template v-if="invoice.insurance_product.name === 'Motor insurance' && invoice.bluecard_finance">
                  <p class="mb-0">
                    Finance: {{ invoice.bluecard_finance.name }}
                    <span v-if="invoice.bluecard_finance.name === 'Others'">({{ invoice.bluecard_finance_remarks }})</span>
                  </p>
                </template>
                <template v-if="invoice.general_remarks !== null">
                  <p class="mb-0">
                    Remarks: {{ invoice.general_remarks }}
                  </p>
                </template>
              </v-col>

              <v-col>
                <p
                  class="me-2 printable-text font-weight-medium"
                  :class="{ 'ml-n13': $vuetify.breakpoint.smAndUp, 'invoice-no-margin': $vuetify.breakpoint.xs }"
                >
                  Official Invoice No:
                  <span class="purple--text">{{ invoice.invoice_number }}</span>
                </p>
                <div>
                  <qrcode-vue
                    :value="invoice.record_preview"
                    level="L"
                  />
                  <p :class="{ 'ml-n5': $vuetify.breakpoint.smAndUp, 'scan-margin': $vuetify.breakpoint.xs }">Scan for authenticity</p>
                </div>
              </v-col>
            </v-row>
          </div>

          <Details :record="invoice" />

          <v-divider class="printable-divider"></v-divider>

          <template>
            <v-divider class="printable-divider"></v-divider>
            <Branches />
          </template>
        </v-card>
      </template>
      <v-card v-if="!invoice">
        <v-skeleton-loader
          height="100%"
          type="image, image, image, image, image, image"
        />
      </v-card>
    </v-col>

    <!-- Right Column: Invoice Action -->
    <v-col
      cols="12"
      md="3"
      class="actions-column"
    >
      <v-card>
        <v-card-text>
          <v-btn
            :disabled="invoice && invoice.deleted_at != null"
            color="primary"
            block
            @click="printInvoice"
          >
            <v-icon
              class="me-2"
              left
            >
              {{ icons.mdiPrinter }}
            </v-icon>
            <span>{{ t('Print') }}</span>
          </v-btn>
        </v-card-text>

        <v-card-text
          v-if="invoice && invoice.deleted_at"
          class="text-center"
        >
          <p class="error--text mb-0">
            {{ t('This invoice has been voided.') }}
          </p>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import {
  ref,
  onUnmounted,
  onMounted,
  inject,
} from '@vue/composition-api'
import router from '@/router'
import { mdiPrinter } from '@mdi/js'
import QrcodeVue from 'qrcode.vue'
import {
  Letterhead,
  Details,
  Branches,
} from '@/components/apps/previews'
import invoiceStoreModule from './invoiceStoreModule'

export default {
  components: {
    Letterhead,
    Details,
    Branches,
    QrcodeVue,
  },
  setup() {
    // Initializers
    const store = inject('store')
    const snackbarService = inject('snackbarService')
    const t = inject('t')
    const INVOICE_APP_STORE_MODULE_NAME = 'app-invoice'

    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) {
      store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule)
    }

    // Properties
    const invoice = ref(null)

    // Methods
    const fetchInvoice = () => {
      store
        .dispatch('app-invoice/fetchInvoice', { id: router.currentRoute.params.id })
        .then(response => {
          invoice.value = response.data.data
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching invoice. Please refresh!')
        })
    }
    const printInvoice = () => window.print()

    window.onbeforeprint = () => {
      document.title = `invoice-${invoice.value.invoice_number}`
    }

    window.onafterprint = () => {
      document.title = 'Niagamas - A Clericle product'
    }

    // Mounted
    onMounted(() => fetchInvoice())

    // Unmounted
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    return {
      t,
      invoice,
      printInvoice,
      icons: {
        mdiPrinter,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/apps/style.scss';

@page {
  size: A4;
  margin: 0mm;
}

.app-preview {
  .printable--theme {
    p {
      font-size: 14px;
    }
  }

  .mobile-text {
    p {
      font-size: 9px;
    }
  }
}

@media print {
  body {
    -webkit-print-color-adjust: exact !important;
    color-adjust: exact !important;
  }

  .v-application {
    background: none !important;
  }

  .app-navigation-menu,
  .v-app-bar,
  .v-footer,
  .app-preview.row .actions-column {
    display: none !important;
  }

  .app-preview {
    .printable--theme {
      border-radius: 0px;

      p {
        font-size: 14px;
      }

      .invoice-no-margin {
        margin-left: -52px !important;
      }

      .scan-margin {
        margin-left: -20px !important;
      }
    }
  }

  .app-preview.row .col-12:first-child {
    max-width: 100% !important;
    flex: 1 !important;
  }

  .v-main,
  .app-content-container {
    padding: 0px !important;
  }

  .v-card {
    box-shadow: none !important;
  }

  .app-preview {
    .invoice-header,
    .payment-details,
    .invoice-total {
      &.d-flex {
        flex-direction: row !important;
      }
    }
  }
}
</style>
